import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Navbar from 'widgets/Navbar/Navbar';

import Spinner from 'components/Spinner';
import TreeSider from 'components/TreeSider/TreeSider';

export default function MainLayout() {
  return (
    <Layout>
      <Suspense fallback={<Spinner />}>
        <Navbar />
      </Suspense>
      <Layout>
        <TreeSider />
        <Content className="content">
          <Suspense fallback={<Spinner />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}
