import { SearchValues } from 'pages/DwgAndVendorPage/SearchServiceUnit/utils';
import {
  CreateCompanyRequest,
  CreateEmergencyTaskRequest,
  CreateServiceUnitRequest,
  CreateUserRequest,
  LoginRequest,
} from 'types';

export const PHONE_REGEX = new RegExp(/^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/);
export const PHONE_ERROR = 'Phone should be +381 64 88 258 99 / +7 987 654 32 10';

export type RuleProps = Record<string, number | string | boolean | RegExp | any>;

export const companyRules: Record<keyof CreateCompanyRequest, RuleProps[]> = {
  name: [{ required: true }, { min: 3 }, { max: 30 }],
  rgbColor: [{ required: true }],
  code: [{ required: true }, { min: 2 }, { max: 7 }],
  description: [{ required: true }, { max: 100 }],
  email: [{ required: true }, { type: 'email' }],
  phone: [{ required: true }, { pattern: PHONE_REGEX, message: PHONE_ERROR }],
  address: [{ required: true }, { min: 1 }, { max: 100 }],
  role: [{ required: true }],
};

export const userRules: Record<keyof CreateUserRequest, RuleProps[]> = {
  name: [{ required: true }, { min: 3 }, { max: 12 }],
  surname: [{ required: true }, { min: 3 }, { max: 12 }],
  email: [{ required: true }, { type: 'email' }],
  phone: [{ required: true }, { pattern: PHONE_REGEX, message: PHONE_ERROR }],
  password1: [{ required: true }, { min: 8 }],
  password2: [{ required: true }, { min: 8 }],
  activityStatus: [],
  canBeResponsible: [],
  role: [{ required: true }],
  companyId: [{ required: true }],
  disciplines: [],
  positionId: [{ required: true }],
};

export const createEmergencyTaskRules: Record<keyof CreateEmergencyTaskRequest, RuleProps[]> = {
  title: [{ required: true }, { min: 5 }, { max: 250 }],
  description: [{ required: true }, { min: 10 }, { max: 100000 }],
  subjectId: [{ required: true }],
  filesIds: [],
  comment: [{ min: 5 }, { max: 3000 }],
  highPriority: [],
  repairType: [{ required: true }],
};

export const editJobRules: Record<string, RuleProps[]> = {
  filesIds: [],
  performerId: [{ required: true }],
  action: [{ required: true }],
  comment: [{ required: true }, { min: 5 }, { max: 3000 }],
  date: [{ required: true }],
  purchasingManagerId: [{ required: true }],
  userIds: [{ required: true }],
};

export const serviceUnitRules: Record<keyof SearchValues, RuleProps[]> = {
  serviceProductId: [],
  sectionId: [],
  disciplineId: [],
  nodeId: [],
  equipmentGroupId: [],
  tagsIds: [],
};

export const createServiceUnitRules: Record<keyof CreateServiceUnitRequest, RuleProps[]> = {
  sectionName: [{ required: true }],
  serviceUnitDisciplineName: [{ required: true }],
  objectName: [{ required: true }],
  companiesIds: [{ required: true }],
  serviceProductId: [{ required: true }],
  tagsIds: [],
  serialNumber: [],
  manufacturerId: [],
  equipmentGroupId: [],
  description: [],
};

export const loginRules: Record<keyof LoginRequest, RuleProps[]> = {
  login: [{ required: true }, { min: 4 }],
  password: [{ required: true }, { min: 8 }],
};
